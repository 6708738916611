import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { auth } from '@/services/firebase';
// import { onAuthStateChanged } from 'firebase/auth';

// Create `axios-cache-adapter` instance
const cache = setupCache({ maxAge: 15 * 60 * 1000 });

let server;

export const getServer = () => {
  if (server) return server;

  server = axios.create({
    headers: { 'Content-Type': 'application/json' },
    adapter: cache.adapter,
  });

  server.interceptors.request.use(
    async (config) => {
      const token = await auth.currentUser?.getIdToken();
      config.headers.Authorization = `Bearer ${token}`; // eslint-disable-line no-param-reassign
      return config;
    }
  );

  server.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) { // eslint-disable-line
        originalRequest._retry = true; // eslint-disable-line no-underscore-dangle
        const token = await auth.currentUser?.getIdToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // eslint-disable-line dot-notation
        return server(originalRequest);
      }

      return Promise.reject(error);
    }
  );

  return server;
};

export default getServer;
