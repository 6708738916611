import createTheme from "@mui/material/styles/createTheme";

const DefaultTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#ffffff' },
    secondary: { main: '#ffffff' },
    background: { default: '#161616' },
  },
  typography: {
    fontSize: 16,
    fontFamily: [
      "Aeonik TRIAL",
      "sans-serif",
    ].join(","),
    body1: {
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: 1.4,
    },
    body2: {
      fontWeight: 300,
      lineHeight: 1.5,
    },
    button: {
      fontSize: '1.3rem',
      fontWeight: 300,
      lineHeight: 1,
    },
    h1: {
      fontSize: '5rem',
      textTransform: "uppercase",
      fontFamily: [
        "PP Eiko",
        "sans-serif",
      ].join(","),
    },
    h2: {
      fontSize: '3.5rem',
      textTransform: "uppercase",
      fontFamily: [
        "PP Eiko",
        "sans-serif",
      ].join(","),
    },
    h3: {
      fontSize: '2.8rem',
      fontFamily: [
        "PP Eiko",
        "sans-serif",
      ].join(","),
    },
  },
});

export default DefaultTheme;
