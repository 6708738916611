import React from "react";
import { useFormik } from "formik";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import PasswordIcon from '@mui/icons-material/Key';

const LoginFormCard = ({ onSubmit }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: onSubmit,
  });

  return (
    <Card className="login-form-card">
      <CardContent sx={{ padding: "3rem 2rem 2.5rem" }}>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="email">
                Email
              </InputLabel>
              <OutlinedInput
                sx={{ marginBottom: "2.5rem" }}
                type="email"
                label="Email"
                name="email"
                id="login-email"
                value={formik.values.email}
                onChange={formik.handleChange}
                endAdornment={(
                    <InputAdornment position="end">
                        <EmailIcon />
                    </InputAdornment>)}
                required
              />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="password">
              Password
            </InputLabel>
            <OutlinedInput
              sx={{ marginBottom: "2.5rem" }}
              type="password"
              label="Password"
              name="password"
              id="login-password"
              value={formik.values.password}
              onChange={formik.handleChange}
              endAdornment={(
                  <InputAdornment position="end">
                      <PasswordIcon />
                  </InputAdornment>)}
              required
            />
          </FormControl>
          <Button type="submit" color='secondary' variant="contained">
            Submit
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default LoginFormCard;
