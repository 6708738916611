import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoginFormCard from "@/ui/organisms/LoginFormCard";
import AuthContext from '@/contexts/Auth';
import { ROOT } from "__urls__";

const loginStyles = makeStyles(() => ({
  page: {
    padding: "7rem 0",
    textAlign: "center",
    minHeight: "100vh",
  },
}));

const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated, login } = useContext(AuthContext);
  const styles = loginStyles();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(ROOT, { replace: true });
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = async (values) => {
    return login(values);
  };

  return (
    <Box id="login-page" className={styles.page}>
      <Container maxWidth="sm">
        <Typography component="h1" variant="h1" mb={4}>
            Login
        </Typography>
        <LoginFormCard onSubmit={onSubmit} />
      </Container>
    </Box>
  );
};

export default Login;
