import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from '@/contexts/Auth';
import { LOGIN } from '__urls__';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  if (!isAuthenticated()) {
    return (
      <Navigate to={{ pathname: LOGIN, state: { from: location } }} replace/>
    );
  }
  return children;
};

export default ProtectedRoute;
