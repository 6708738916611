import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import Fuse from 'fuse.js';
import { useSnackbar } from 'notistack';
import { useThrottleCallback } from '@react-hook/throttle';

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";

import ClearIcon from '@mui/icons-material/Clear';

import AuthContext from '@/contexts/Auth';
import FilteringTableHead from "@/ui/organisms/FilteringTableHead";
import Page from "@/ui/templates/Page";
import { getProducts } from '_Api/products';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const Dashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isAuthenticated } = useContext(AuthContext);
  const [products, setProducts] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState('');
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const tableHeadings = [
    {
      id: "id",
      numeric: true,
      disablePadding: false,
      label: "ID",
    },
    {
      id: "brand",
      numeric: false,
      disablePadding: false,
      label: "Brand",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Color Name",
    },
    {
      id: "finished",
      numeric: false,
      disablePadding: false,
      label: "Finished",
    },
    {
      id: "thickness",
      numeric: true,
      disablePadding: false,
      label: "Thickness",
    },
    {
      id: "size",
      numeric: true,
      disablePadding: false,
      label: "Size",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: false,
      label: "Price",
    },
  ];

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - products.length) : 0;

  useEffect(() => {
    if (isAuthenticated() && !loaded) {
      setLoaded(true);
      getProducts((list) => {
        setProducts(list);
        setFiltered(list);
      });
      enqueueSnackbar("Loading data, please wait...", { variant: 'info' });
    }
  }, []); // eslint-disable-line

  const fuse = React.useMemo(
    () => new Fuse(products, {
      threshold: 0.5,
      ignoreLocation: true,
      keys: ['brand', 'name', 'finished', 'thickness', 'size'],
    }),
    [products],
  );

  const executeSearch = useCallback((newSearch) => {
    if (!newSearch) {
      setFiltered(products);
      setOrderBy('id');
      setOrder('asc');
      return;
    }
    const newFiltered = fuse.search(newSearch);
    setPage(0);
    setOrder('asc');
    setOrderBy('score');
    setFiltered(newFiltered.map(({ item }) => item));
  }, [fuse, setPage, setFiltered, products]);
  const throttleSearch = useThrottleCallback(executeSearch, 2, false);

  const productsRender = useMemo(() => (
    Object.values(filtered)
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(getComparator(order, orderBy))
      .map(product => (
        <TableRow key={product.id}>
          <TableCell align="right">{product.id}</TableCell>
          <TableCell>{product.brand}</TableCell>
          <TableCell>{product.name}</TableCell>
          <TableCell>{product.finished}</TableCell>
          <TableCell align="right">{product.thickness}</TableCell>
          <TableCell align="right">{product.size}</TableCell>
          <TableCell align="right">{product.price}</TableCell>
        </TableRow>
      ))
  ), [filtered, order, orderBy, page, rowsPerPage]);

  return (
    <Page title='Products listing'>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <TextField
          label='Search'
          value={search}
          onChange={(ev) => {
            const newValue = ev.target.value;
            setSearch(newValue);
            throttleSearch(newValue);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{ opacity: search ? 1 : 0 }}
                  aria-label="clear search"
                  onClick={() => {
                    setSearch('');
                    throttleSearch('');
                  }}
                  edge="end"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <TableContainer sx={{ mt: 3 }}>
        <Table size="medium">
          <FilteringTableHead
            tableHeadings={tableHeadings}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filtered.length}
          />
          <TableBody>
            { productsRender }
            {emptyRows > 0 && (
                <TableRow
                    style={{ height: 50 * emptyRows }}
                >
                    <TableCell colSpan={4} />
                </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 50, 100, 300, 1000]}
        component="div"
        count={filtered.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Page>
  );
};

export default Dashboard;
