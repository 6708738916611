import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import AppBar from "@/ui/organisms/AppBar";

const DashboardTemplate = ({ title, children }) => {
  return (
    <Box
      id="dashboard-page"
      sx={{
        textAlign: "center",
        minHeight: "100vh",
      }}
    >
      <AppBar />
      <Box py={12}>
        <Container maxWidth="lg">
          <Typography variant='h1' component="h1" mb={6}>{title}</Typography>
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default DashboardTemplate;
