import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from '@mui/utils';

const FilteringTableHead = ({ tableHeadings, order, orderBy, onRequestSort }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
        <TableHead>
            <TableRow>
                { tableHeadings.map(heading => (
                    <TableCell
                        key = { heading.id }
                        align = { heading.numeric ? "right" : "left" }
                        padding = { heading.disablePadding ? "none" : "normal" }
                        sortDirection = { orderBy === heading.id ? order : "asc" }
                        sx={{ fontWeight: "bold", textTransform: "uppercase" }}
                    >
                        <TableSortLabel
                            active = { orderBy === heading.id }
                            direction = { orderBy === heading.id ? order : 'asc' }
                            onClick={ createSortHandler(heading.id) }
                        >
                            { heading.label }
                            { orderBy === heading.id ? (
                                <Box
                                    component="span"
                                    sx={visuallyHidden}
                                >
                                    { order === 'desc' ? 'sorted descending' : 'sorted ascending' }
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
  );
};

export default FilteringTableHead;
