import { getServer } from "@/services/server";
import { PRODUCTS_ROOT, PRODUCT } from '__api_urls__';

export const getProducts = (setter) => {
  getServer().get(PRODUCTS_ROOT)
    .then(({ data }) => {
      setter(data.products);
    });
};

export const getProduct = async (productName) => {
  const response = await getServer().get(PRODUCT(productName));
  return response.data.product;
};
