import React, { createContext, useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";

import { auth } from '@/services/firebase';
// import { isExpiredToken } from '@/services/jwt';

export const AuthContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  isAuthenticated: () => false,
});

const { Provider } = AuthContext;

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const setError = (err) => {
    console.error(err);
    enqueueSnackbar("An error has ocurred", { variant: 'error' });
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        enqueueSnackbar(`Goodbye!`, { variant: 'info' });
      // Sign-out successful.
      })
      .catch(setError);
  };

  const isAuthenticated = () => {
    // if (!user || typeof user.email === 'undefined' || isExpiredToken(user.token)) {
    return Boolean(user);
  };

  const login = ({ email, password }) => {
    signInWithEmailAndPassword(auth, email, password)
      // .then(async (userCredential) => {
      .then(async () => {
        enqueueSnackbar(`Welcome back ${email}!`, { variant: 'success' });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("An error has occurred, please validate your email and password and try again.", { variant: 'error' });
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, setUser, setError);
    return () => unsubscribe();
  }, []); // eslint-disable-line

  const hook = {
    user,
    login,
    logout,
    isAuthenticated,
  };

  return (
    <Provider value={hook}>{children}</Provider>
  );
};

export default AuthContext;
