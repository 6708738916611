import React from "react";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "@/ui/atoms/ProtectedRoute";

import LoginPage from "@/pages/Login";
import DashboardPage from "@/pages/Dashboard";

import * as URLS from "__urls__";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        index
        path={URLS.ROOT}
        element={(
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        )}
      />
      <Route path={URLS.LOGIN} element={(<LoginPage/>)} />
    </Routes>
  );
};

export default AppRoutes;
