import React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { AuthProvider } from "@/contexts/Auth";
import DefaultTheme from "__themes__/DefaultTheme";
import AppRoutes from "./AppRoutes";
import "__fonts__/AeonikTRIAL/stylesheet.css";
import "__fonts__/PPEiko/stylesheet.css";

function App() {
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    if (notistackRef.current) notistackRef.current.closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={DefaultTheme}>
      <CssBaseline enableColorScheme/>
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        maxSnack={3}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)} aria-label="close">
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      >
        <AuthProvider>
          <div className="App">
            <Router>
              <AppRoutes />
            </Router>
          </div>
        </AuthProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
